import React from 'react'
import { useSelector } from 'react-redux';
import { Header } from '../Header';
import { Details } from './Details';

export const SpecialRequestScreen = () => {
  const { info } = useSelector((state) => state.traveler);  

  return (
    <div>      
      <Header info={info} />          
      <Details info={info} />
    </div>
  )
}
