import { viajesApi } from "../../../api/viajesApi";
import { setCourtshipLine, setFullGuestList, setFullWeedingPassList, setGuestList, setHotelImages, setInfo, setSeatingPlan, setSpecialRequests, setTransportChurch, setTransportRequests, setWeedingPassList } from "./groupSlice";
import Swal from "sweetalert2";
import fileDownload from 'js-file-download'
import { setPaymentsTutorial } from "../tutorials/tutorialsSlice";

export const search = (code, success) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/search", {
        params: {
          code
        }
      });
      success();      
      dispatch(setInfo(resp.data.results));
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");      
      dispatch(setInfo({}));
    }
  }
}

export const addPaymentMasterSale = (idMasterSaleGroup, date, amount, idPaymentWay, image,
 notes, code, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idMasterSaleGroup", idMasterSaleGroup);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      if (image)
        form.append("image", image, image.Name);
      form.append("notes", notes ? notes : "");

      const resp = await viajesApi.post("/group/addPaymentMasterSale", form);
      success();
      dispatch(search(code, () => {}));
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const roomingListDetails = (code, idHotel) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/roomingListDetails", {
        params: {
          code, idHotel
        },
        responseType: 'arraybuffer',
        headers: { 'Content-Type': 'blob' },
      });
      fileDownload(resp.data, `${code} - Rooming List.xlsx`);
      Swal.fire("Archivos", "Rooming List descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const groupReservationVoucher = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/groupReservationVoucherById", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Comprobante de pago.pdf`);
      Swal.fire("Archivos", "Comprobante descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const downloadContract = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/downloadContract", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Contrato.pdf`);
      Swal.fire("Archivos", "Contrato descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const downloadModificationPolicy = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/downloadModificationPolicy", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Politica de modificacion.pdf`);
      Swal.fire("Archivos", "Politica de modificacion descargada correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const downloadCancellationPolicy = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/downloadCancellationPolicy", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Politica de cancelacion.pdf`);
      Swal.fire("Archivos", "Politica de cancelacion descargada correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const addElementToCourtshipLine = (idGroupFile, name, person1, telephone1, person2, telephone2, 
  code, success) => {
 
   return async (dispatch, getState) => {
 
     try {
       
       const resp = await viajesApi.post("/group/addElementToCourtshipLine", {
        idGroupFile, name, person1, telephone1, person2, telephone2
       });
       success();
       dispatch(getCourtshipLine(code));
       Swal.fire("Linea De Cortejo", resp.data.msg, "success");
     } catch (error) {
       Swal.fire("Linea De Cortejo", error.response.data.msg, "error");
     }
   }
 }

 export const editElementToCourtshipLine = (id, name, person1, telephone1, person2, telephone2, 
  code, success) => {

  return async (dispatch, getState) => {

    try {

      const resp = await viajesApi.put("/group/editElementToCourtshipLine", {
        id, name, person1, telephone1, person2, telephone2, 
      });
      success();
      dispatch(getCourtshipLine(code));
      Swal.fire("Linea De Cortejo", resp.data.msg, "success");
    } catch (error) {
      console.log(error)
      Swal.fire("Linea De Cortejo", error.response.data.msg, "error");
    }
  }
}

export const deleteElementFromCourtshipLine = (id, code, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/group/deleteElementFromCourtshipLine", {
        params: { id }
      });
      success();
      dispatch(getCourtshipLine(code));
      Swal.fire("Linea De Cortejo", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Linea De Cortejo", error.response.data.msg, "error");
    }
  }
}

export const moveElementToCourtshipLine = (idGroupFile, id, action,
  code) => {

  return async (dispatch, getState) => {

    try {

      const resp = await viajesApi.put("/group/moveElementToCourtshipLine", {
        idGroupFile, id, action,
      });
      dispatch(getCourtshipLine(code));
      Swal.fire("Linea De Cortejo", resp.data.msg, "success");
    } catch (error) {
      console.log(error)
      Swal.fire("Linea De Cortejo", error.response.data.msg, "error");
    }
  }
}

export const addTableToSeatingPlan = (idGroupFile, code, success) => {
 
   return async (dispatch, getState) => {
 
     try {
       
       const resp = await viajesApi.post("/group/addTableToSeatingPlan", {
        idGroupFile, code
       });
       success();
       dispatch(getSeatingPlan(code));
       Swal.fire("Plan De Mesas", resp.data.msg, "success");
     } catch (error) {
       Swal.fire("Plan De Mesas", error.response.data.msg, "error");
     }
   }
 }

 export const getGuestList = (code) => {

  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/getGuestList",
      {
        params: {
          code
        }
      });
      dispatch(setGuestList(resp.data.results.map(item => ({ "value": item.idGuestRoom, "label": item.guest }))));
    } catch (error) {

    }
  }
}

export const deleteTableFromSeatingPlan = (id, code, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/group/deleteTableFromSeatingPlan", {
        params: { id, code }
      });
      success();
      dispatch(getSeatingPlan(code));
      Swal.fire("Plan De Mesas", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Plan De Mesas", error.response.data.msg, "error");
    }
  }
}

export const getSeatingPlan = (code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/getSeatingPlan", {
        params: {
          code
        }
      });
      dispatch(setSeatingPlan(resp.data.results));
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
      dispatch(setSeatingPlan([]));
    }
  }
}

export const editGuestInChair = (idGuest, idWeedingPass, idChair, code, success) => {
 
  return async (dispatch, getState) => {
    
    try {
      const resp = await viajesApi.put("/group/editGuestInChair", {
        idGuest, idWeedingPass, idChair, code
      });
      success();
      dispatch(getSeatingPlan(code));
      dispatch(getGuestList(code));
      dispatch(getWeedingPassList(code));
      Swal.fire("Plan De Mesas", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Plan De Mesas", error.response.data.msg, "error");
    }
  }
}

export const deleteGuestInChair = (idChair, code, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/group/deleteGuestInChair", {
        params: { idChair, code }
      });
      success();
      dispatch(getSeatingPlan(code));
      dispatch(getGuestList(code));
      dispatch(getWeedingPassList(code));
      Swal.fire("Plan De Mesas", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Plan De Mesas", error.response.data.msg, "error");
    }
  }
}

export const getFullGuestList = (code) => {

  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/getFullGuestList",
      {
        params: {
          code
        }
      });
      dispatch(setFullGuestList(resp.data.results.map(item => ({ "value": item.idGuestRoom, "label": item.guest }))));
    } catch (error) {

    }
  }
}

export const getCourtshipLine = (code) => {

  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/getCourtshipLine", {
        params: {
          code
        }
      });
      dispatch(setCourtshipLine(resp.data.results));
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
      dispatch(setCourtshipLine([]));
    }
  }
}

export const addRequestAirportHotel = (code, flight, airline, arrivalDate, terminal,
  mode, people, names, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/group/addRequestAirportHotel", {
        code, flight, airline, arrivalDate, terminal,
        mode, people, names
      });
      success();
      dispatch(transportRequests(code));
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const addRequestHotelAirport = (code, flight, airline, departureDate, terminal,
  mode, people, names, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/group/addRequestHotelAirport", {
        code, flight, airline, departureDate, terminal,
        mode, people, names
      });
      success();
      dispatch(transportRequests(code));
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const transportRequests = (code) => {
  return async (dispatch, getState) => {
    try {
      
      const resp = await viajesApi.get("/group/transportRequests", {
        params: {
          code
        }
      });

      dispatch(setTransportRequests(resp.data.results));
    } catch (error) {
      dispatch(setTransportRequests([]));
    }
  }
}

export const getGuestTransportChurchList = (code) => {
  return async (dispatch, getState) => {
    try {
      
      const resp = await viajesApi.get("/group/guestTransportChurchList", {
        params: {
          code
        }
      });

      dispatch(setTransportChurch(resp.data.results));
    } catch (error) {
      dispatch(setTransportChurch([]));
    }
  }
}

export const addGuestChurch = (code, idGuest) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/group/addGuestChurch", {
        code, idGuest
      });
      dispatch(getGuestTransportChurchList(code));
      Swal.fire("Iglesia", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Iglesia", error.response.data.msg, "error");
    }
  }
}

export const deleteGuestChurch = (code, idGuest) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/group/deleteGuestChurch", {
        params: { idGuest, code }
      });
      dispatch(getGuestTransportChurchList(code));
      Swal.fire("Iglesia", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Iglesia", error.response.data.msg, "error");
    }
  }
}

export const getHotelImages = (code) => {
  return async (dispatch, getState) => {
    try {
      
      const resp = await viajesApi.get("/group/getHotelImages", {
        params: {
          code
        }
      });

      dispatch(setHotelImages(resp.data.results));
    } catch (error) {
      dispatch(setHotelImages([]));
    }
  }
}

export const getWeedingPassList = (code) => {

  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/getWeedingPassList",
      {
        params: {
          code
        }
      });

      dispatch(setWeedingPassList(resp.data.results.map(
        item => ({ "value": item.idWeedingPass, "label": item.name }))));
    } catch (error) {

    }
  }
}

export const getFullExternalGuestList = (code) => {

  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/group/getFullExternalGuestList",
      {
        params: {
          code
        }
      });
      
      dispatch(setFullWeedingPassList(resp.data.results.map(item => ({ "value": item.idWeedingPass, "label": item.externalGuest }))));
    } catch (error) {

    }
  }
}

export const specialRequests = (code) => {
  return async (dispatch, getState) => {
    try {

      const resp = await viajesApi.get("/group/specialRequests", {
        params: {
          code
        }
      });

      dispatch(setSpecialRequests(resp.data.results));
    } catch (error) {
      dispatch(setSpecialRequests([]));
    }
  }
}

export const addSpecialRequest = (code, request, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/group/addSpecialRequest", {
        code, request
      });
      success();
      dispatch(specialRequests(code));
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const getPaymentsTutorial = () => {
  return async (dispatch, getState) => {
    try {
      
      const resp = await viajesApi.get("/group/paymentsTutorial");
      dispatch(setPaymentsTutorial(resp.data.results));
    } catch (error) {
      dispatch(setPaymentsTutorial([]));
    }
  }
}