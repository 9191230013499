import { RejectedPaymentDetails } from './RejectedPaymentDetails';

export const RejectedPayment = ({masterSale, code}) => {

  return (
    <div style={{marginTop:20}}>
      <div style={{ fontSize: 20 }}>Pagos rechazados</div>     

      <RejectedPaymentDetails masterSale={masterSale} code={code}/>
    </div>
  )
}
