import { viajesApi } from "../../../api/viajesApi";
import { setHotelImages, setInfo, setSpecialRequests, setTransportRequests } from "./guestSlice";
import Swal from "sweetalert2";
import fileDownload from 'js-file-download'
import { setPaymentsTutorial } from "../tutorials/tutorialsSlice";

export const search = (code, success) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/guest/search", {
        params: {
          code
        }
      });
      success();
      dispatch(setInfo(resp.data.results));
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
      dispatch(setInfo({}));
    }
  }
}

export const addPaymentMasterSale = (idMasterSaleFile, date, amount, idPaymentWay, image,
  notes, code, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idMasterSaleFile", idMasterSaleFile);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      if (image)
        form.append("image", image, image.Name);
      form.append("notes", notes ? notes : "");

      const resp = await viajesApi.post("/guest/addPaymentMasterSale", form);
      success();
      dispatch(search(code, () => { }));
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const groupReservationVoucher = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/guest/groupReservationVoucherById", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Comprobante de pago.pdf`);
      Swal.fire("Archivos", "Comprobante descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", "Ha ocurrido un error", "error");
    }
  }
}

export const travelVoucher = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/guest/travelVoucherById", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Recibo de viaje.pdf`);
      Swal.fire("Archivos", "Comprobante descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", "Ha ocurrido un error", "error");
    }
  }
}

export const bookingCoupon = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/guest/bookingCouponById", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Cupon de reserva.pdf`);
      Swal.fire("Archivos", "Cupon descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const addRequestAirportHotel = (code, flight, airline, arrivalDate, terminal,
  mode, people, names, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/guest/addRequestAirportHotel", {
        code, flight, airline, arrivalDate, terminal,
        mode, people, names
      });
      success();
      dispatch(transportRequests(code));
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const addRequestHotelAirport = (code, flight, airline, departureDate, terminal,
  mode, people, names, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/guest/addRequestHotelAirport", {
        code, flight, airline, departureDate, terminal,
        mode, people, names
      });
      success();
      dispatch(transportRequests(code));
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const transportRequests = (code) => {
  return async (dispatch, getState) => {
    try {
      
      const resp = await viajesApi.get("/guest/transportRequests", {
        params: {
          code
        }
      });

      dispatch(setTransportRequests(resp.data.results));
    } catch (error) {
      dispatch(setTransportRequests([]));
    }
  }
}

export const getHotelImages = (code) => {
  return async (dispatch, getState) => {
    try {
      
      const resp = await viajesApi.get("/guest/getHotelImages", {
        params: {
          code
        }
      });

      dispatch(setHotelImages(resp.data.results));
    } catch (error) {
      dispatch(setHotelImages([]));
    }
  }
}

export const downloadContract = (id, code) => {
  return async (dispatch, getState) => {
    try {
      console.log(id + " " + code)
      const resp = await viajesApi.get("/guest/downloadContract", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      console.log(id + " ZZZ")
      fileDownload(resp.data, `Contrato.pdf`);
      Swal.fire("Archivos", "Contrato descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const downloadModificationPolicy = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/guest/downloadModificationPolicy", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Politica de modificacion.pdf`);
      Swal.fire("Archivos", "Politica de modificacion descargada correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const downloadCancellationPolicy = (id, code) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/guest/downloadCancellationPolicy", {
        params: {
          id, code
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Politica de cancelacion.pdf`);
      Swal.fire("Archivos", "Politica de cancelacion descargada correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const specialRequests = (code) => {
  return async (dispatch, getState) => {
    try {

      const resp = await viajesApi.get("/guest/specialRequests", {
        params: {
          code
        }
      });

      dispatch(setSpecialRequests(resp.data.results));
    } catch (error) {
      dispatch(setSpecialRequests([]));
    }
  }
}

export const addSpecialRequest = (code, request, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/guest/addSpecialRequest", {
        code, request
      });
      success();
      dispatch(specialRequests(code));
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const getPaymentsTutorial = () => {
  return async (dispatch, getState) => {
    try {
      
      const resp = await viajesApi.get("/guest/paymentsTutorial");
      dispatch(setPaymentsTutorial(resp.data.results));
    } catch (error) {
      dispatch(setPaymentsTutorial([]));
    }
  }
}