import { RejectedPaymentDetails } from './RejectedPaymentDetails';

export const RejectedPayment = ({masterSaleGroup, code, ref2}) => {

  return (
    <div style={{marginTop:20}} ref={ref2}>
      <div style={{ fontSize: 20 }}>Pagos rechazados de grupo</div>
      <RejectedPaymentDetails masterSaleGroup={masterSaleGroup} code={code} />
    </div>
  )
}
