import { useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { currencyFormat } from "../../../helpers/numericFields";
import styles from './WeedingPassDetails.module.css'
import { EditWeedingPass } from "./EditWeedingPass";
import { ModalWeedingPassImage } from "../../ui/controls/ModalWeedingPassImage";

export const WeedingPassDetails = ({ groupFile }) => {
  const { role } = useSelector((state) => state.auth);
  const { weedingPass } = useSelector((state) => state.groupFiles);
  const [viewImage, setViewImage] = useState(false);
  const [editWeedingPass2, setEditWeedingPass2] = useState(false);
  const [weedingPassSelected, setWeedingPassSelected] = useState({});


  const edit2 = (weedingPass) => {
    setEditWeedingPass2(true);
    setWeedingPassSelected(weedingPass);
  }

  const viewImage2 = (weedingPass) => {
    setViewImage(true);
    setWeedingPassSelected(weedingPass);
  }


  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Nombre</th>
              <th>Fecha de nacimiento</th>
              <th>Costo</th>
              <th>Estado</th>
              <th>Comprobante</th>
              <th>¿Se queda en el hotel?</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              weedingPass.map((weedingPass2, index) => (
                <tr key={weedingPass2.id}>
                  <td>{index + 1}</td>
                  <td>{weedingPass2.name}</td>
                  <td className="break-word ">{weedingPass2.birthdate}</td>
                  <td>{currencyFormat(weedingPass2.amount)}</td>
                  <td>{weedingPass2.statusWeedingPass}</td>
                  <td style={{ textAlign: "center" }}>
                    {
                      weedingPass2.image &&
                      <i className={`fa fa-image ${styles.edit}`} onClick={() => viewImage2(weedingPass2)}></i>
                    }
                  </td>
                  <td>{(weedingPass2.stayAtHotel)? "Si" : "No"}</td>
                  {
                    role === "DIRECTOR"
                    && <td style={{ textAlign: "center" }}>
                      <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(weedingPass2)}></i>
                    </td>
                  }
                </tr>))
            }
          </tbody>
        </Table>
      </div>

      <EditWeedingPass weedingPass={weedingPassSelected} groupFile={groupFile}
        isOpen={editWeedingPass2} setIsOpen={setEditWeedingPass2} />
      <ModalWeedingPassImage code={groupFile.code} id={weedingPassSelected.id} isOpen={viewImage} setIsOpen={setViewImage} />
    </div>
  )
}
