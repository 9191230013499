import { viajesApi } from "../../../api/viajesApi";
import Swal from "sweetalert2";
import { setFiles } from "./representativeSlice";

export const fileByRep = (idRep) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/files/filesByRep", {
        params: {
          idRep
        }
      });
      dispatch(setFiles(resp.data.results));
    } catch (error) {
      Swal.fire("Agentes", error.response.data.msg, "error");
      dispatch(setFiles([]));
    }
  }
}