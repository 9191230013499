import { useState } from "react";
import { Table } from "react-bootstrap";
import styles from './GeneralInfo.module.css'
import { Header } from "./Header";
import { EditGroupFile } from "../EditGroupFile";
import { Documents } from "../Documents/Documents";

export const GeneralInfo = ({ groupFile }) => {  

  const [edit, setEdit] = useState(false);

  return (
    <>
      <Header groupFile={groupFile} />

      <div className="table-responsive">
        <Table className="table border text-nowrap text-md-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Contacto</th>
              <th>Telefono</th>
              <th>Celular</th>
              <th>Fechas Viaje</th>
              <th>Fecha Limite Pago</th>
              <th>Destino</th>
              <th>Categoria</th>
              <th>Hospedaje</th>
              <th>Codigo</th>
              <th>Abierto</th>
              <th>Visible</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td>{groupFile.customer}</td>
              <td>{groupFile.telephone}</td>
              <td>{groupFile.cellphone}</td>
              <td>{groupFile.startDate + " - " + groupFile.endDate}</td>
              <td>{groupFile.paydayLimit}</td>
              <td>{groupFile.tags}</td>
              <td>{groupFile.category}</td>
              <td>{groupFile.lodging}</td>
              <td>{groupFile.code}</td>
              <td style={{ textAlign: "center" }}>{groupFile.isOpen ? <i className="fa fa-check" /> : ""}</td>
              <td style={{ textAlign: "center" }}>{groupFile.isVisible ? <i className="fa fa-check" /> : ""}</td>
              <td style={{ textAlign: "center" }}>
                <i className={`fe fe-edit ${styles.edit}`} onClick={() => setEdit(true)}></i>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <EditGroupFile groupFile={groupFile} isOpen={edit} setIsOpen={setEdit} />
      <Documents groupFile={groupFile} />
    </>
  )
}
