import { useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { currencyFormat } from "../../../helpers/numericFields";
import styles from './AdvancePaymentDetails.module.css'
import { EditAdvancePayment } from "./EditAdvancePayment";
import { Link } from "react-router-dom";
import { ModalPaymentImage } from "../../ui/controls/ModalPaymentImage";

export const AdvancePaymentDetails = ({ pendingPayments }) => {
  const [editAdvancePayment, setEditAddAdvancePayment] = useState(false);
  const [viewImage, setViewImage] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState({});

  const edit2 = (payment) => {
    setEditAddAdvancePayment(true);
    setPaymentSelected(payment)
  }

  const viewImage2 = (payment) => {
    setViewImage(true);
    setPaymentSelected(payment)
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Pago #</th>
              <th>Fecha</th>
              <th>Cliente</th>
              <th>#Exp</th>
              <th>Grupo</th>
              <th>Metodo</th>
              <th>Forma De Pago</th>
              <th>Referencia</th>
              <th>Comprobante</th>
              <th>Monto</th>
              <th>Notas</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              pendingPayments.map((payment, index) => (
                <tr key={payment.id}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td>{payment.customer}</td>
                  <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip >Ver Expediente</Tooltip>}>
                      <Link to={`/panel/expedienteGrupo?id=${payment.idGroupFile}`}
                        className="btn btn-primary btn-sm rounded-11 me-2" ><span className="fs-15 fw-semibold text-center">{payment.idGroupFile}</span></Link>
                    </OverlayTrigger>
                  </td>
                  <td>{payment.group2}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>
                  <td>{payment.reference}</td>
                  <td style={{ textAlign: "center" }}>
                    {
                      payment.image === 1
                        ? <i className={`fa fa-image ${styles.edit}`} onClick={() => viewImage2(payment)}></i>
                        : null
                    }
                  </td>
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(payment)}></i>
                  </td>
                </tr>))
            }
          </tbody>
        </Table>
      </div>
      <EditAdvancePayment payment={paymentSelected}
        isOpen={editAdvancePayment} setIsOpen={setEditAddAdvancePayment} />
      <ModalPaymentImage code={paymentSelected.code} id={paymentSelected.id} isOpen={viewImage} setIsOpen={setViewImage} />
      <div className={styles.total}>Total: {
        currencyFormat(pendingPayments.reduce((a, b) => a += b.amount, 0))}
      </div>
    </div>
  )
}
