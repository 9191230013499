import { useState } from "react";
import { Table } from "react-bootstrap";
import { currencyFormat } from "../../../helpers/numericFields";
import styles from './AdvancePaymentDetails.module.css'
import { EditAdvancePayment } from "./EditAdvancePayment";
import { useDispatch } from 'react-redux';
import { travelVoucher } from "../../../store/slices/files/thunks";
import { ModalPaymentImage } from "../../ui/controls/ModalPaymentImage";

export const AdvancePaymentDetails = ({ masterSale, code }) => {
  const [editAdvancePayment, setEditAddAdvancePayment] = useState(false);
  const [viewImage, setViewImage] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState({});
  const dispatch = useDispatch();

  const edit2 = (payment) => {
    setEditAddAdvancePayment(true);
    setPaymentSelected(payment)
  }

  const viewImage2 = (payment) => {
    setViewImage(true);
    setPaymentSelected(payment)
  }

  const downloadTravelVoucher = (id) => {
    dispatch(travelVoucher(id));
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-nowrap mg-b-0">
          <thead>
            <tr>
              <th>Pago #</th>
              <th>Fecha</th>
              <th>Agente</th>
              <th>Metodo</th>
              <th>Forma De Pago</th>
              <th>Referencia</th>
              <th>Evidencia</th>
              <th>Factura</th>              
              <th>Monto</th>
              <th>Notas</th>
              <th>Aprobado</th>
              <th>Recibo Viaje</th>
              <th>Editar</th>
            </tr>
          </thead>
          <tbody>
            {
              masterSale.advancePayments.map((payment, index) => (
                <tr key={payment.id}>
                  <td>{index + 1}</td>
                  <td>{payment.date}</td>
                  <td>{payment.agent}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentWay}</td>
                  <td>{payment.reference}</td>
                  <td style={{ textAlign: "center" }}>
                    {
                      payment.image === 1
                        ? <i className={`fa fa-image ${styles.edit}`} onClick={() => viewImage2(payment)}></i>
                        : ""
                    }
                  </td>
                  <td>{payment.invoice ? "Si" : "No"}</td>                  
                  <td>{currencyFormat(payment.amount)}</td>
                  <td>{payment.notes}</td>
                  <td style={{ textAlign: "center" }}>
                    {payment.approved === 1 ? <i className="fa fa-check" /> : ""}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {
                      payment.approved === 1
                        ? <i className={`fa fa-file-pdf-o ${styles.edit}`} onClick={() => downloadTravelVoucher(payment.id)}></i>
                        : null
                    }
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => edit2(payment)}></i>
                  </td>
                </tr>))
            }            
          </tbody>
        </Table>
      </div>
      <EditAdvancePayment payment={paymentSelected} masterSale={masterSale} 
        isOpen={editAdvancePayment} setIsOpen={setEditAddAdvancePayment} />
      <ModalPaymentImage code={code} id={paymentSelected.id} isOpen={viewImage} setIsOpen={setViewImage} />
      <div className={styles.total}>Total: {
        currencyFormat(masterSale.advancePayments.reduce((a,b) => (b.approved === 1) ? a += b.amount : a,0))}
      </div>
    </div>
  )
}
