import { Button, Modal, Card, Carousel } from "react-bootstrap";
import placeHolder from '../../../../images/placeholder-image.png';
import styles from './PaymentsTutorial.module.css';
import { useState } from "react";

export const PaymentsTutorial = ({ paymentsTutorial, isOpen, setIsOpen }) => {    
  const [hide, setHide] = useState(false);

  return (
    <>
      <Modal
        size="xl"
        dialogClassName="modal-100w"
        show={isOpen}
        aria-labelledby="example-modal-sizes-title-lg"
        className={hide && styles.modal}
      >
        <Modal.Header>
          <Modal.Title >
            Tutorial para realizar pagos
          </Modal.Title>
          <Button
            onClick={() => setIsOpen(false)}
            className="btn-close"
            variant=""
          >
            x
          </Button>
        </Modal.Header>

        <Modal.Body>
          <Card className="productdesc-1">
            <Card.Body className="bg_gray">
              <div className="carousel slide  p-2 border br-5">
                <Carousel indicators={false}>
                  {paymentsTutorial.map((image, k) => (
                    <Carousel.Item key={k}>
                      <img className={`${styles.image} br-5`} alt="Tutorial de pago" src={process.env.REACT_APP_URL_TUTORIAL_IMAGES + "?id=" + image.id}
                      onError={(e)=> e.target.src=placeHolder} />
                      <div className={`${styles.text} mt-4 text-center pb-4`}>
                        <label className="">{image.text}</label>
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
                </div>
            </Card.Body>
          </Card>
        </Modal.Body>

      </Modal>
    </>
  );
}
