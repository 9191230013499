import { useState } from "react";
import { Table } from "react-bootstrap";
import styles from './PaymentsTutorialDetails.module.css';
import placeHolder from '../../../images/placeholder-image.png';
import { deletePaymentsTutorialImage } from "../../../store/slices/tutorials/thunks";
import { useDispatch } from 'react-redux';
import { EditPaymentsTutorialImage } from "./EditPaymentsTutorialImage";

export const PaymentsTutorialDetails = ({ paymentsTutorial }) => {
  const dispatch = useDispatch();
  const [editImage, setEditImage] = useState(false)
  const [imageSelected, setImageSelected] = useState({});  

  const editImage2 = (image) => {
    setEditImage(true);
    setImageSelected(image)
  }

  const deleteImage = (image) => {
    dispatch(deletePaymentsTutorialImage(image));
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 50, border: "1 solid gray", background: "white", padding: 20 }}>
      <div className="table-responsive">
        <Table className="table border text-wrap mg-b-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Imagen</th>                  
              <th>Texto</th>
              <th>Editar</th>
              <th>Eliminar</th>
            </tr>
          </thead>
          <tbody>
            {
              paymentsTutorial.map((image, index) => (
                <tr key={image.image}>
                  <td>{index + 1}</td>
                  <td><img
                    className={styles.image}
                    src={process.env.REACT_APP_URL_TUTORIAL_IMAGES + '?id=' + image.id}
                    onError={(e) => e.target.src = placeHolder}
                    alt={"image"} />
                  </td>               
                  <td>{image.text}</td>  
                  <td style={{ textAlign: "center" }}>
                    <i className={`fe fe-edit ${styles.edit}`} onClick={() => editImage2(image)}></i>
                  </td> 
                  <td><button className="btn btn-danger"
                    onClick={() => deleteImage(image.image)}>Eliminar</button></td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
      <EditPaymentsTutorialImage image={imageSelected} isOpen={editImage} setIsOpen={setEditImage} />
    </div>
  )
}
