import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  logged: false,
  name: "",
  userName: "",
  role: "",
  checking: true,  
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setStart: (state, action) => {
      state.checking= true
    },
    setLogin: (state, action) => {
      state.logged=true
      state.name= action.payload.name
      state.userName= action.payload.userName
      state.role= action.payload.role      
      state.checking=false
    },
    setLogout: (state, action) => {
      state.logged=false
      state.name=""
      state.userName=""
      state.role=""
      state.checking=false
    },
    setEnd: (state, action) => {
      state.checking=false     
    },
  },
})

// Action creators are generated for each case reducer function
export const { setStart, setLogin, setLogout, setEnd } = authSlice.actions;