import { useDispatch, useSelector } from 'react-redux';
import { ViewImages } from '../Guest/ViewImages'
import styles from './Header.module.css'
import { useState } from 'react';
import { getHotelImages, getPaymentsTutorial } from '../../store/slices/group/thunks';
import { PaymentsTutorial } from '../ui/tutorials/payment/PaymentsTutorial';


export const Header = ({info}) => {
  const dispatch = useDispatch();
  const [ viewHotelImages, setViewHotelImages ] = useState(false);
  const [ viewPaymentsTutorial, setViewPaymentsTutorial ] = useState(false);
  const { hotelImages } = useSelector((state) => state.group);
  const { paymentsTutorial } = useSelector((state) => state.tutorials);
  

  const showHotelImages = () => {
    dispatch(getHotelImages(info.code));
    setViewHotelImages(true);
  }

  const showPaymentsTutorial = () => {
    dispatch(getPaymentsTutorial());
    setViewPaymentsTutorial(true);
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 20 }}>
      <div className={`${styles.title}`}>Grupo: {info.group2}</div>
      <div style={{ display: 'flex', justifyContent: "space-between" }}>
        <div>
          <div className={`${styles.subTitleBlock}`}>
            <label className={`${styles.subTitle}`}>Cliente:</label><label>{info.customer}</label>
          </div>
          <div className={`${styles.subTitleBlock}`}>
            <label className={`${styles.subTitle}`}>Destino:</label><label>{info.tags}</label>
          </div>
        </div>
        <div>
          <button className={`${styles.images} btn btn-info`} onClick={showHotelImages}>Imagenes de hotel</button>
          <button className={`${styles.images} btn btn-info`} onClick={showPaymentsTutorial}>Tutorial de pago</button>
        </div>
      </div>      
      <ViewImages images={hotelImages
          .map(image => {
          return {
            image: process.env.REACT_APP_URL_HOTELS_IMAGES + "?id=" + image.id,
            text: ""
          }
        })}
         isOpen={viewHotelImages} setIsOpen={setViewHotelImages} />
      <PaymentsTutorial paymentsTutorial={paymentsTutorial} isOpen={viewPaymentsTutorial} setIsOpen={setViewPaymentsTutorial} />
      <br/>
      <div >
        Nota: Si tienes alguna duda respecto de tus pagos, por favor comunicate al 614 192 2828 o 614 362 3709
      </div>
    </div>    
  )
}
