import { viajesApi } from "../../../api/viajesApi";
import Swal from "sweetalert2";
import { clearIndividualSale, setIndividualFilesList } from "../groupFiles/groupFilesSlice";
import { resetMasterSale, setCustomerPayments, setIndividualFile, setMasterSale, setOptionSelected, setPassengersList, setPendingAirportHotel, setPendingHotelAirport, setPendingPayments, setPendingSpecialRequest, setRoomingList } from "./individualFilesSlice";
import fileDownload from 'js-file-download'

export const addIndividualFile = (idCustomer, owner, idGroupFile, rooms, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/individualFiles/addIndividualFile", {
        idCustomer, owner, idGroupFile, rooms
      });
      success();
      dispatch(clearIndividualSale())
      dispatch(getFilesByIdGroup(idGroupFile))
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const editIndividualFile = (id, idCustomer, owner, requestDate, isVisible, remove, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editIndividualFile", {
        id, idCustomer, owner, requestDate, isVisible, remove
      });
      success();
      dispatch(getIndividualFileById(id))
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const addIndividualRoom = (idIndividualFile, idRoom, quantity, checkInDate, checkOutDate,
  success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/individualFiles/addIndividualRoom", {
        idIndividualFile, idRoom, quantity, checkInDate, checkOutDate
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile))
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      console.log(error.response)
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const editIndividualRoom = (id, checkInDate, checkOutDate, idIndividualFile,
  success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editIndividualRoom", {
        id, checkInDate, checkOutDate
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile))
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const deleteIndividualRoom = (id, idIndividualFile, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/individualFiles/deleteIndividualRoom", {
        params: { id }
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile))
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const getIndividualFileById = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/byId", {
        params: {
          id
        }
      });
      dispatch(setIndividualFile(resp.data.results));
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");

      dispatch(setIndividualFile({id:-1}));
    }
  }
}


export const addPassenger = (name, lastName, mothersLastName, relationship, birthdate, idIndividualFile, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/individualFiles/addPassenger", {
        idIndividualFile, name, lastName, mothersLastName, relationship, birthdate,
      });
      success();
      dispatch(getPassengersByIdIndividualFile(idIndividualFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      console.log(error.response)
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const getPassengersByIdIndividualFile = (idIndividualFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/passengersByIdIndividualFile", {
        params: {
          idIndividualFile
        }
      });
      dispatch(setPassengersList(resp.data.results));
    } catch (error) {
      dispatch(setPassengersList([]));
    }
  }
}

export const deletePassengerIndividualFile = (id, idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/individualFiles/deletePassenger", { params: { id } });
      success();
      dispatch(getPassengersByIdIndividualFile(idIndividualFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const editPassengerIndividualFile = (id, name, lastName, mothersLastName, relationship, birthdate, idIndividualFile,
  success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editPassenger", {
        id, name, lastName, mothersLastName, relationship, birthdate
      });
      success();
      dispatch(getPassengersByIdIndividualFile(idIndividualFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }
  }
}

export const getMasterSaleByIndividualFile = (idIndividualFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/masterSaleByIndividualFile", {
        params: {
          idIndividualFile
        }
      });
      dispatch(setMasterSale(resp.data.results));
    } catch (error) {
      dispatch(resetMasterSale());
    }
  }
}

export const addServiceMasterSale = (idMasterSaleFile, idservice, idWholesaler, startDate, endDate, quantity,
  ticket, reserve, description, costPerUnit, feeService, tax, others, fee, idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/individualFiles/addServiceMasterSale", {
        idMasterSaleFile, idservice, idWholesaler, startDate, endDate, quantity,
        ticket, reserve, description, costPerUnit, feeService, tax, others, fee,
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile));
      Swal.fire("Servicio", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Servicio", error.response.data.msg, "error");
    }
  }
}

export const editServiceMasterSale = (id, idservice, idWholesaler, startDate, endDate, quantity,
  ticket, reserve, description, costPerUnit, feeService, tax, others, fee, idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editServiceMasterSale", {
        id, idservice, idWholesaler, startDate, endDate, quantity,
        ticket, reserve, description, costPerUnit, feeService, tax, others, fee,
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile));
      Swal.fire("Servicio", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Servicio", error.response.data.msg, "error");
    }
  }
}

export const deleteServiceMasterSale = (id, idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/individualFiles/deleteServiceMasterSale", { params: { id } });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile));
      Swal.fire("Servicio", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Servicio", error.response.data.msg, "error");
    }
  }
}

export const openCloseMasterSale = (idMasterSaleFile, isOpen, idIndividualFile) => {

  return async (dispatch, getState) => {

    try {
      await viajesApi.put("/individualFiles/openCloseMasterSale", {
        idMasterSaleFile, isOpen,
      });
      if (isOpen) {
        dispatch(setOptionSelected("Quotes"))
      } else {
        dispatch(setOptionSelected("MasterSale"))
      }
      dispatch(getMasterSaleByIndividualFile(idIndividualFile))
    } catch (error) {
    }
  }
}

export const getFilesByIdGroup = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/filesByIdGroup", {
        params: {
          idGroupFile
        }
      });
      dispatch(setIndividualFilesList(resp.data.results));
    } catch (error) {
      dispatch(setIndividualFilesList([]));
    }
  }
}

export const getCustomerPayments = (idGroupFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/customerPayments", {
        params: {
          idGroupFile
        }
      });
      dispatch(setCustomerPayments(resp.data.results));
    } catch (error) {
      dispatch(setCustomerPayments([]));
    }
  }
}

export const addPaymentMasterSale = (idMasterSaleFile, date, amount, idPaymentWay, reference, image,
  invoice, notes, idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("idMasterSaleFile", idMasterSaleFile);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      form.append("reference", reference ? reference : "");
      if (image)
        form.append("image", image, image.Name);
      form.append("invoice", invoice);
      form.append("notes", notes ? notes : "");

      const resp = await viajesApi.post("/individualFiles/addPaymentMasterSale", form);
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile));
      dispatch(getIndividualFileById(idIndividualFile))
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const editPaymentMasterSale = (id, date, amount, idPaymentWay, reference, image, 
  invoice, notes, approved, idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("id", id);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      form.append("reference", reference ? reference : "");
      if (image)
        form.append("image", image, image.Name);
      form.append("invoice", invoice);
      form.append("notes", notes ? notes : "");
      form.append("approved", approved);

      const resp = await viajesApi.put("/individualFiles/editPaymentMasterSale", form);
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile));
      dispatch(getIndividualFileById(idIndividualFile))
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const deletePaymentMasterSale = (id,
  idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/individualFiles/deletePaymentMasterSale", {
        params: { id }
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile));
      dispatch(getIndividualFileById(idIndividualFile))
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const rejectPaymentMasterSale = (id, notes, idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/individualFiles/rejectPaymentMasterSale", {
        params: { id, notes }
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile));
      dispatch(getIndividualFileById(idIndividualFile))
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const addPaymentWholesaler = (id, concept, date, amount, idPaymentWay, notes,
  idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.post("/individualFiles/addPaymentWholesaler", {
        id, concept, date, amount, idPaymentWay, notes
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile))
      dispatch(getIndividualFileById(idIndividualFile))
      Swal.fire("Pago a Mayorista", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pago a Mayorista", error.response.data.msg, "error");
    }
  }
}

export const editPaymentWholesaler = (id, concept, date, amount, idPaymentWay, notes,
  idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editPaymentWholesaler", {
        id, concept, date, amount, idPaymentWay, notes
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile))
      dispatch(getIndividualFileById(idIndividualFile))
      Swal.fire("Pago a Mayorista", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pago a Mayorista", error.response.data.msg, "error");
    }
  }
}

export const deletePaymentWholesaler = (id, concept, idIndividualFile, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/individualFiles/deletePaymentWholesaler", {
        params: { id, concept }
      });
      success();
      dispatch(getMasterSaleByIndividualFile(idIndividualFile))
      dispatch(getIndividualFileById(idIndividualFile))
      Swal.fire("Pago a Mayorista", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pago a Mayorista", error.response.data.msg, "error");
    }
  }
}

export const getRoomingListByIndividualFile = (idIndividualFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/roomingListByIndividualFile", {
        params: {
          idIndividualFile
        }
      });
      dispatch(setRoomingList(resp.data.results));
    } catch (error) {
      dispatch(setRoomingList([]));
    }
  }
}

export const editGuestRoom = (id, name, birthdate, idIndividualFile,
  success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editGuestRoom", {
        id, name, birthdate
      });
      success();
      dispatch(getRoomingListByIndividualFile(idIndividualFile))
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const editRoomNotes = (id, notes, idIndividualFile,
  success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editRoomNotes", {
        id, notes
      });
      success();
      dispatch(getRoomingListByIndividualFile(idIndividualFile))
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const editRoomReserve = (id, reserve, idIndividualFile,
  success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editRoomReserve", {
        id, reserve
      });
      success();
      dispatch(getRoomingListByIndividualFile(idIndividualFile))
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const getPendingPayments = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/pendingPayments", );
      dispatch(setPendingPayments(resp.data.results));
    } catch (error) {

    }
  }
}

export const editPendingPayment = (id, date, amount, idPaymentWay, reference, image, 
  invoice, notes, approved, success) => {

  return async (dispatch, getState) => {

    try {
      var form = new FormData();
      form.append("id", id);
      form.append("date", date);
      form.append("amount", amount);
      form.append("idPaymentWay", idPaymentWay);
      form.append("reference", reference ? reference : "");
      if (image)
        form.append("image", image, image.Name);
      form.append("invoice", invoice);
      form.append("notes", notes ? notes : "");
      form.append("approved", approved);

      const resp = await viajesApi.put("/individualFiles/editPaymentMasterSale", form);
      success();
      dispatch(getPendingPayments());
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const deletePendingPayment = (id, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/individualFiles/deletePaymentMasterSale", {
        params: { id }
      });
      success();
      dispatch(getPendingPayments());
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}

export const rejectPendingPayment = (id, notes, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.delete("/individualFiles/rejectPaymentMasterSale", {
        params: { id, notes }
      });
      success();
      dispatch(getPendingPayments());
      Swal.fire("Pagos", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Pagos", error.response.data.msg, "error");
    }
  }
}


export const groupReservationVoucher = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/groupReservationVoucherById", {
        params: {
          id
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Comprobante de pago.pdf`);
      Swal.fire("Archivos", "Comprobante descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}


export const travelVoucher = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/travelVoucherById", {
        params: {
          id
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Recibo de viaje.pdf`);
      Swal.fire("Archivos", "Comprobante descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const updateNotes = (notes, idIndividualFile, success) => {
  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/updateNotes", {
        idIndividualFile, notes
      });
      success();
      dispatch(getIndividualFileById(idIndividualFile));
      Swal.fire("Expediente", resp.data.msg, "success");
    } catch (error) {
      console.log(error.response)
      Swal.fire("Expediente", error.response.data.msg, "error");
    }

  }
}

export const bookingCoupon = (id) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/bookingCouponById", {
        params: {
          id
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Cupon de reserva.pdf`);
      Swal.fire("Archivos", "Cupon descargado correctamente", "success");
    } catch (error) {
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getPendingAirportHotel = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/pendingAirportHotel",);
      dispatch(setPendingAirportHotel(resp.data.results));
    } catch (error) {

    }
  }
}

export const editAirportHotel = (id, notes, statusRequest, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editRequestAirportHotel", {
        id, notes, statusRequest
      });
      success();
      dispatch(getPendingAirportHotel());
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const getPendingHotelAirport = () => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/pendingHotelAirport",);
      dispatch(setPendingHotelAirport(resp.data.results));
    } catch (error) {

    }
  }
}

export const editHotelAirport = (id, notes, statusRequest, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editRequestHotelAirport", {
        id, notes, statusRequest
      });
      success();
      dispatch(getPendingHotelAirport());
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}

export const downloadPayments = (idIndividualFile) => {
  return async (dispatch, getState) => {
    try {
      const resp = await viajesApi.get("/individualFiles/downloadPayments", {
        params: {
          idIndividualFile
        },
        responseType: 'blob',
      });
      fileDownload(resp.data, `Pagos.zip`);
      Swal.fire("Archivos", "Pagos descargados correctamente", "success");
    } catch (error) {
      console.log(error)
      Swal.fire("Archivos", error.response.data.msg, "error");
    }
  }
}

export const getPendingSpecialRequest = () => {
  return async (dispatch, getState) => {
    try {      
      const resp = await viajesApi.get("/individualFiles/pendingSpecialRequest",);
      dispatch(setPendingSpecialRequest(resp.data.results));
    } catch (error) {

    }
  }
}

export const editSpecialRequest = (id, answer, statusRequest, success) => {

  return async (dispatch, getState) => {

    try {
      const resp = await viajesApi.put("/individualFiles/editSpecialRequest", {
        id, answer, statusRequest
      });
      success();
      dispatch(getPendingSpecialRequest());
      Swal.fire("Solicitudes", resp.data.msg, "success");
    } catch (error) {
      Swal.fire("Solicitudes", error.response.data.msg, "error");
    }
  }
}