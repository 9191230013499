import React, { useState } from 'react'
import { AgencyPaymentSchemeDetails, WeedingPassDetails } from './WeedingPassDetails'
import { useEffect } from 'react'
import { getAgencyPaymentScheme, getWeedingPass, weedingPassListDetails } from '../../../store/slices/groupFiles/thunks'
import { useDispatch } from 'react-redux'
import { AddWeedingPass } from './AddWeedingPass'

export const WeedingPass = ({ groupFile }) => {
  const dispatch = useDispatch();
  const [addWeedingPass, setAddWeedingPass] = useState(false);

  useEffect(() => {
    dispatch(getWeedingPass(groupFile.id));
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ fontSize: 20 }}>Weeding Pass Adicionales</div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button className="btn btn-light btn-icon"
          onClick={() => setAddWeedingPass(true)} >Agregar Weeding Pass</button>
        <button className={`btn text-white btn-primary`}
          onClick={() => dispatch(weedingPassListDetails(groupFile.id))} >Descargar Weeding Pass List</button>
      </div>

      <AddWeedingPass groupFile={groupFile} isOpen={addWeedingPass} setIsOpen={setAddWeedingPass} />
      <WeedingPassDetails groupFile={groupFile} code={groupFile} />
    </div>
  )
}
